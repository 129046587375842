import { Component, OnInit, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CropperDialogResult, GeneralService } from "@sportyano/core/services/generals/general.service";
import { ImageCroppedEvent, ImageTransform } from "ngx-image-cropper";

export type CropperDialogData = {
  image: File;
  width: number;
  height: number;
};

@Component({
  selector: "app-image-cropper-tool",
  templateUrl: "./image-cropper-tool.component.html",
  styleUrl: "./image-cropper-tool.component.scss",
})
export class ImageCropperToolComponent implements OnInit {
  transform: ImageTransform = { scale: 1 };
  scale = 1; 
  initialScale = 1; 
  minScale = 0.5; 
  maxScale = 3; 
  aspectRatio: number;

  constructor(private generalService: GeneralService) {}
  data: CropperDialogData = inject(MAT_DIALOG_DATA);
  private dialogRef = inject(MatDialogRef<ImageCropperToolComponent>);
  croppedImage: CropperDialogResult;

  ngOnInit(): void {
    this.calculateAspectRatio();
    this.setMinScale();
  }

  calculateAspectRatio(): void {
    const { width, height } = this.data;
    this.aspectRatio = width / height;
  }

  setMinScale(): void {
    this.minScale = Math.max(this.aspectRatio, 0.5); 
  }

  onWheel(event: WheelEvent): void {
    event.preventDefault();
    if (event.deltaY < 0) {
      this.zoomIn();
    } else {
      this.zoomOut();
    }
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === '+') {
      this.zoomIn();
    } else if (event.key === '-') {
      this.zoomOut();
    }
  }

  zoomIn(): void {
    if (this.scale < this.maxScale) {
      this.scale = Math.min(this.scale + 0.1, this.maxScale);
      this.transform = { ...this.transform, scale: this.scale };
    }
  }

  zoomOut(): void {
    if (this.scale > this.initialScale) {
      this.scale = Math.max(this.scale - 0.1, this.initialScale);
      this.transform = { ...this.transform, scale: this.scale };
    }
  }

  imageCropped(event: ImageCroppedEvent): void {
    const { blob, objectUrl } = event;
    if (blob && objectUrl) {
      this.croppedImage = { blob: blob, imageUrl: objectUrl };
    }
  }

  sendResult() {
    if (this.croppedImage) {
      this.generalService.setCroppedImageState(this.croppedImage);
      this.dialogRef.close(this.croppedImage);
    } else {
      console.error("No cropped image to send");
      this.dialogRef.close(null); 
    }
  }

  // Close the dialog
  closeDialog() {
    this.dialogRef.close();
  }
}
