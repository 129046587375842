<mat-dialog-content (wheel)="onWheel($event)" (keydown)="onKeyDown($event)" tabindex="0">
	<mat-dialog-content>
		<image-cropper [cropperMinWidth]="data.width" [cropperMinHeight]="data.width" [imageFile]="data.image"
			[maintainAspectRatio]="true" [resizeToHeight]="data.height"
			 [resizeToWidth]="data.width" 
			 format="jpeg" [aspectRatio]="data.width/data.height"
			 (imageCropped)="imageCropped($event)" [transform]="transform"
			 >
		</image-cropper>
	</mat-dialog-content>

	<mat-dialog-actions >
		<button mat-button mat-dialog-close  (click)="closeDialog()">{{'cropperImage.cancel'|translate}}</button>
		<button mat-button mat-dialog-close cdkFocusInitial (click)="sendResult()">{{'cropperImage.add'|translate}}</button>
	</mat-dialog-actions>

</mat-dialog-content>